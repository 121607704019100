/* global axios */

import ApiClient from './ApiClient';

class PushNotifications extends ApiClient {
  constructor() {
    super('push_notifications', { accountScoped: true });
  }

  get({ searchKey }) {
    const url = searchKey ? `${this.url}?search=${searchKey}` : this.url;
    return axios.get(url);
  }
}

export default new PushNotifications();
