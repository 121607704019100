import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import PushDevicesAPI from '../../api/pushDevices';

const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
    fetchingItem: false,
    creatingItem: false,
    updatingItem: false,
    deletingItem: false,
  },
};

const getters = {
  getAllPushDevices(_state) {
    return _state.records;
  },
  getPushUIFlags(_state) {
    return _state.uiFlags;
  },
};

const actions = {
  getPushDevices: async function getPushDevices(
    { commit },
    { searchKey } = {}
  ) {
    commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { fetchingList: true });
    try {
      const response = await PushDevicesAPI.get({ searchKey });
      commit(types.default.SET_PUSH_DEVICES, response.data);
      commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { fetchingList: false });
    } catch (error) {
      commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { fetchingList: false });
    }
  },

  createPushDevices: async function createPushDevices(
    { commit },
    pushDevicesObj
  ) {
    commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { creatingItem: true });
    try {
      const response = await PushDevicesAPI.create(pushDevicesObj);
      commit(types.default.ADD_PUSH_DEVICES, response.data);
      commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { creatingItem: false });
    } catch (error) {
      commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { creatingItem: false });
    }
  },

  updatePushDevices: async function updatePushDevices(
    { commit },
    { id, ...updateObj }
  ) {
    commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { updatingItem: true });
    try {
      const response = await PushDevicesAPI.update(id, updateObj);
      commit(types.default.EDIT_PUSH_DEVICES, response.data);
      commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { updatingItem: false });
    } catch (error) {
      commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { updatingItem: false });
    }
  },

  deletePushDevices: async function deletePushDevices({ commit }, id) {
    commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { deletingItem: true });
    try {
      await PushDevicesAPI.delete(id);
      commit(types.default.DELETE_PUSH_DEVICES, id);
      commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { deletingItem: true });
    } catch (error) {
      commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { deletingItem: true });
    }
  },
};

const mutations = {
  [types.default.SET_PUSH_DEVICES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_PUSH_DEVICES]: MutationHelpers.set,
  [types.default.ADD_PUSH_DEVICES]: MutationHelpers.create,
  [types.default.EDIT_PUSH_DEVICES]: MutationHelpers.update,
  [types.default.DELETE_PUSH_DEVICES]: MutationHelpers.destroy,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
