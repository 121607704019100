import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import PushChannelsAPI from '../../api/channel/pushChannel';

const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
    fetchingItem: false,
    creatingItem: false,
    updatingItem: false,
    deletingItem: false,
  },
};

const getters = {
  getPushChannels(_state) {
    return _state.records;
  },
  getPushChannelsUIFlags(_state) {
    return _state.uiFlags;
  },
  getPushChannelsById: _state => channelId => {
    return _state.records.find(value => value.id === Number(channelId));
  },
};

const actions = {
  getPushChannels: async function getPushChannels(
    { commit },
    { searchKey } = {}
  ) {
    commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { fetchingList: true });
    try {
      const response = await PushChannelsAPI.get({ searchKey });
      commit(types.default.SET_PUSH_CHANNELS, response.data);
      commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { fetchingList: false });
    } catch (error) {
      commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { fetchingList: false });
    }
  },

  createPushChannels: async function createPushChannels(
    { commit },
    pushDevicesObj
  ) {
    commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { creatingItem: true });
    try {
      const response = await PushChannelsAPI.create(pushDevicesObj);
      commit(types.default.ADD_PUSH_CHANNELS, response.data);
      commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { creatingItem: false });
    } catch (error) {
      commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { creatingItem: false });
    }
  },

  updatePushChannels: async function updatePushChannels(
    { commit },
    { id, ...updateObj }
  ) {
    commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { updatingItem: true });
    try {
      const response = await PushChannelsAPI.update(id, updateObj);
      commit(types.default.EDIT_PUSH_CHANNELS, response.data);
      commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { updatingItem: false });
    } catch (error) {
      commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { updatingItem: false });
    }
  },

  deletePushDevices: async function deletePushDevices({ commit }, id) {
    commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { deletingItem: true });
    try {
      await PushChannelsAPI.delete(id);
      commit(types.default.DELETE_PUSH_CHANNELS, id);
      commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { deletingItem: true });
    } catch (error) {
      commit(types.default.SET_PUSH_CHANNELS_UI_FLAG, { deletingItem: true });
    }
  },
};

const mutations = {
  [types.default.SET_PUSH_CHANNELS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_PUSH_CHANNELS]: MutationHelpers.set,
  [types.default.ADD_PUSH_CHANNELS]: MutationHelpers.create,
  [types.default.EDIT_PUSH_CHANNELS]: MutationHelpers.update,
  [types.default.DELETE_PUSH_CHANNELS]: MutationHelpers.destroy,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
