<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('PUSH_DEVICES_MGMT.ADD.TITLE')"
        :header-content="$t('PUSH_DEVICES_MGMT.ADD.DESC')"
      />
      <form class="row" @submit.prevent="addAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.token.$error }">
            {{ $t('PUSH_DEVICES_MGMT.ADD.FORM.TOKEN.LABEL') }}
            <input
              v-model.trim="token"
              type="text"
              :placeholder="$t('PUSH_DEVICES_MGMT.ADD.FORM.TOKEN.PLACEHOLDER')"
              @input="$v.token.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.name.$error }">
            {{ $t('PUSH_DEVICES_MGMT.ADD.FORM.NAME.LABEL') }}
            <input
              v-model.trim="name"
              type="text"
              :placeholder="$t('PUSH_DEVICES_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.name.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.version.$error }">
            {{ $t('PUSH_DEVICES_MGMT.ADD.FORM.VERSION.LABEL') }}
            <input
              v-model.trim="version"
              type="text"
              :placeholder="
                $t('PUSH_DEVICES_MGMT.ADD.FORM.VERSION.PLACEHOLDER')
              "
              @input="$v.version.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.model.$error }">
            {{ $t('PUSH_DEVICES_MGMT.ADD.FORM.MODEL.LABEL') }}
            <input
              v-model.trim="model"
              type="text"
              :placeholder="$t('PUSH_DEVICES_MGMT.ADD.FORM.MODEL.PLACEHOLDER')"
              @input="$v.model.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.bundle.$error }">
            {{ $t('PUSH_DEVICES_MGMT.ADD.FORM.BUNDLE.LABEL') }}
            <input
              v-model.trim="bundle"
              type="text"
              :placeholder="$t('PUSH_DEVICES_MGMT.ADD.FORM.BUNDLE.PLACEHOLDER')"
              @input="$v.bundle.$touch"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.token.$invalid ||
                  $v.name.$invalid ||
                  $v.version.$invalid ||
                  $v.model.$invalid ||
                  $v.bundle.$invalid ||
                  addPushDevices.showLoading
              "
              :button-text="$t('PUSH_DEVICES_MGMT.ADD.FORM.SUBMIT')"
              :loading="addPushDevices.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('PUSH_DEVICES_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  mixins: [alertMixin],
  props: {
    responseContent: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      token: '',
      name: '',
      version: '',
      model: '',
      bundle: '',
      addPushDevices: {
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  validations: {
    token: {
      required,
    },
    name: {
      required,
    },
    version: {
      required,
    },
    model: {
      required,
    },
    bundle: {
      required,
    },
  },
  methods: {
    resetForm() {
      this.token = '';
      this.name = '';
      this.version = '';
      this.model = '';
      this.bundle = '';
      this.$v.token.$reset();
      this.$v.name.$reset();
      this.$v.version.$reset();
      this.$v.model.$reset();
      this.$v.bundle.$reset();
    },
    addAgent() {
      // Show loading on button
      this.addPushDevices.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('createPushDevices', {
          push_devices_token: this.token,
          push_devices_name: this.name,
          push_devices_version: this.version,
          push_devices_model: this.model,
          push_devices_bundle: this.bundle,
        })
        .then(() => {
          // Reset Form, Show success message
          this.addPushDevices.showLoading = false;
          this.showAlert(this.$t('PUSH_DEVICES_MGMT.ADD.API.SUCCESS_MESSAGE'));
          this.resetForm();
          this.onClose();
        })
        .catch(() => {
          this.addPushDevices.showLoading = false;
          this.showAlert(this.$t('PUSH_DEVICES_MGMT.ADD.API.ERROR_MESSAGE'));
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
}
</style>
