<template>
  <div class="betatester-table-container-only-tester">
    <h5>Beta tester Information</h5>
    <div v-if="safeBetaTester.email != ''">
      <table class="betatester-table">
        <thead>
          <tr>
            <th class="betatester-first-column">Name</th>
            <th class="betatester-first-column">Email</th>
            <th class="betatester-first-column">Invitation type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="betatester-first-column">
              {{ safeBetaTester.firstName }}
              {{ safeBetaTester.lastName }}
            </td>
            <td class="betatester-first-column">
              {{ safeBetaTester.email }}
            </td>
            <td class="betatester-second-column">
              {{ safeBetaTester.inviteType }}
            </td>
          </tr>
        </tbody>
      </table>

      <table v-if="safeBetaTester.groups" class="betatester-table">
        <thead>
          <tr>
            <th class="betatester-first-column betatester-groups-column">
              Groups invited
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="grp in safeBetaTester.groups" :key="grp.id">
            <td
              class="betatester-second-column betatester-groups-column single-colum"
            >
              <span>
                {{ grp.attributes.name }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table v-else class="betatester-table">
        <thead>
          <tr>
            <th class="betatester-first-column betatester-groups-column">
              This user is not in any group yet
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <div v-else>This user is not in beta tester program</div>
    <div class="betatester-table-container">
      <woot-button
        v-if="safeBetaTester.email == ''"
        variant="flat"
        icon="send"
        @click="
          $emit(
            'inviteToBetaTest',
            contactInfo.name,
            contactInfo.email,
            contactInfo.id
          )
        "
      />

      <woot-button variant="flat" icon="repeat" @click="$emit('refreshInfo')" />

      <woot-button
        variant="flat"
        icon="delete"
        color-scheme="alert"
        @click="$emit('deleteBetaTester', safeBetaTester.id)"
      />

      <woot-button
        variant="flat"
        icon="dismiss"
        color-scheme="alert"
        @click="$emit('closeModal')"
      >
        Cancel
      </woot-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    betaTesterData: {
      type: Object,
      default: () => {},
    },
    contactInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    safeBetaTester() {
      let safeBTester = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        inviteType: '',
        groups: [],
      };

      if (this.betaTesterData.result) {
        let betaGroups = this.betaTesterData.result.included.betaGroups;
        safeBTester.groups = betaGroups;
        if (
          this.betaTesterData.result.data &&
          this.betaTesterData.result.data[0]
        ) {
          const betaTestersData = this.betaTesterData.result.data;
          const ids = betaTestersData.map(item => item.id);

          let info = this.betaTesterData.result.data[0].attributes;
          safeBTester.id = ids || [];
          safeBTester.name = info.name || '';
          safeBTester.lastName = info.lastName || '';
          safeBTester.email = info.email || '';
          safeBTester.inviteType = info.inviteType || '';
        }
      }
      return safeBTester;
    },
  },
  watch: {
    betaTesterData(betaTesterNewData, betaTesterOldData) {
      if (betaTesterNewData && betaTesterNewData !== betaTesterOldData) {
        this.updateLabels();
      }
    },
  },
  mounted() {
    this.updateLabels();
  },
  methods: {
    async updateLabels() {
      let condition =
        this.isPending(this.contactInfo.id) &&
        this.betaTesterData.result !== undefined &&
        this.betaTesterData.result.data !== undefined &&
        this.betaTesterData.result.data.length > 0;

      if (condition) {
        await this.$emit('updateLabel', this.contactInfo.id, ['beta-testers']);
      }
    },
    async isPending(contactId) {
      if (contactId == null || contactId === '') {
        return true;
      }
      await this.$store.dispatch('contactLabels/get', contactId);

      const availableContactLabels = this.$store.getters[
        'contactLabels/getContactLabels'
      ](this.contactId);

      return availableContactLabels.includes('pending');
    },
  },
};
</script>

<style lang="scss">
.betatester-first-column {
  text-align: center;
}

.betatester-table-container-only-tester {
  overflow: scroll;
}
.single-colum {
  text-align: left;
}
</style>
