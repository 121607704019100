<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.PUSH_CHANNELS.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.PUSH_CHANNELS.DESC')"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.PUSH_CHANNELS.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.PUSH_CHANNELS.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.PUSH_CHANNELS.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.pushChannelKey.$error }">
          {{ $t('INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_KEY.LABEL') }}
          <textarea
            v-model.trim="pushChannelKey"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_KEY.PLACEHOLDER')
            "
            @blur="$v.pushChannelKey.$touch"
          />
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.pushChannelTeamId.$error }">
          {{ $t('INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_KEY_ID.LABEL') }}
          <input
            v-model.trim="pushChannelKeyId"
            type="text"
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_KEY_ID.PLACEHOLDER'
              )
            "
            @blur="$v.pushChannelKeyId.$touch"
          />
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.pushChannelTeamId.$error }">
          {{ $t('INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_TEAM_ID.LABEL') }}
          <input
            v-model.trim="pushChannelTeamId"
            type="text"
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_TEAM_ID.PLACEHOLDER'
              )
            "
            @blur="$v.pushChannelTeamId.$touch"
          />
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.pushChannelBundle.$error }">
          {{ $t('INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_BUNDLE.LABEL') }}
          <input
            v-model.trim="pushChannelBundle"
            type="text"
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_BUNDLE.PLACEHOLDER'
              )
            "
            @blur="$v.pushChannelBundle.$touch"
          />
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.pushChannelServerPath.$error }">
          {{
            $t('INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_SERVER_PATH.LABEL')
          }}
          <input
            v-model.trim="pushChannelServerPath"
            type="text"
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.PUSH_CHANNELS.PUSH_CHANNELS_SERVER_PATH.PLACEHOLDER'
              )
            "
            @blur="$v.pushChannelServerPath.$touch"
          />
        </label>
      </div>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.PUSH_CHANNELS.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      channelName: '',
      pushChannelKey: '',
      pushChannelKeyId: '',
      pushChannelTeamId: '',
      pushChannelBundle: '',
      pushChannelServerPath: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    channelName: { required },
    pushChannelKey: { required },
    pushChannelKeyId: { required },
    pushChannelTeamId: { required },
    pushChannelBundle: { required },
    pushChannelServerPath: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const pushChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.channelName,
            channel: {
              type: 'push',
              push_channels_key: this.pushChannelKey,
              push_channels_keyId: this.pushChannelKeyId,
              push_channels_teamId: this.pushChannelTeamId,
              push_channels_bundle: this.pushChannelBundle,
              push_channels_server_path: this.pushChannelServerPath,
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: pushChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.PUSH_CHANNELS.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
