var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"betatester-table-container-only-tester"},[_c('h5',[_vm._v("Beta tester Information")]),_vm._v(" "),(_vm.safeBetaTester.email != '')?_c('div',[_c('table',{staticClass:"betatester-table"},[_vm._m(0),_vm._v(" "),_c('tbody',[_c('tr',[_c('td',{staticClass:"betatester-first-column"},[_vm._v("\n            "+_vm._s(_vm.safeBetaTester.firstName)+"\n            "+_vm._s(_vm.safeBetaTester.lastName)+"\n          ")]),_vm._v(" "),_c('td',{staticClass:"betatester-first-column"},[_vm._v("\n            "+_vm._s(_vm.safeBetaTester.email)+"\n          ")]),_vm._v(" "),_c('td',{staticClass:"betatester-second-column"},[_vm._v("\n            "+_vm._s(_vm.safeBetaTester.inviteType)+"\n          ")])])])]),_vm._v(" "),(_vm.safeBetaTester.groups)?_c('table',{staticClass:"betatester-table"},[_vm._m(1),_vm._v(" "),_c('tbody',_vm._l((_vm.safeBetaTester.groups),function(grp){return _c('tr',{key:grp.id},[_c('td',{staticClass:"betatester-second-column betatester-groups-column single-colum"},[_c('span',[_vm._v("\n              "+_vm._s(grp.attributes.name)+"\n            ")])])])}),0)]):_c('table',{staticClass:"betatester-table"},[_vm._m(2)])]):_c('div',[_vm._v("This user is not in beta tester program")]),_vm._v(" "),_c('div',{staticClass:"betatester-table-container"},[(_vm.safeBetaTester.email == '')?_c('woot-button',{attrs:{"variant":"flat","icon":"send"},on:{"click":function($event){return _vm.$emit(
          'inviteToBetaTest',
          _vm.contactInfo.name,
          _vm.contactInfo.email,
          _vm.contactInfo.id
        )}}}):_vm._e(),_vm._v(" "),_c('woot-button',{attrs:{"variant":"flat","icon":"repeat"},on:{"click":function($event){return _vm.$emit('refreshInfo')}}}),_vm._v(" "),_c('woot-button',{attrs:{"variant":"flat","icon":"delete","color-scheme":"alert"},on:{"click":function($event){return _vm.$emit('deleteBetaTester', _vm.safeBetaTester.id)}}}),_vm._v(" "),_c('woot-button',{attrs:{"variant":"flat","icon":"dismiss","color-scheme":"alert"},on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._v("\n      Cancel\n    ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"betatester-first-column"},[_vm._v("Name")]),_vm._v(" "),_c('th',{staticClass:"betatester-first-column"},[_vm._v("Email")]),_vm._v(" "),_c('th',{staticClass:"betatester-first-column"},[_vm._v("Invitation type")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"betatester-first-column betatester-groups-column"},[_vm._v("\n            Groups invited\n          ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"betatester-first-column betatester-groups-column"},[_vm._v("\n            This user is not in any group yet\n          ")])])])
}]

export { render, staticRenderFns }