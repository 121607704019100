/* global axios */

import PublicApiClient from './PublicApiClient';

class PushDevices extends PublicApiClient {
  constructor() {
    super('push_devices', { accountScoped: false });
  }

  get({ searchKey }) {
    const url = searchKey ? `${this.url}?search_key=${searchKey}` : this.url;
    return axios.get(url);
  }
}

export default new PushDevices();
