import SettingsContent from '../Wrapper';
import PushDevicesHome from './Index';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/push-devices'),
      component: SettingsContent,
      props: {
        headerTitle: 'PUSH_DEVICES_MGMT.HEADER',
        icon: 'brand-push',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'push_devices_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'push_devices_list',
          roles: ['administrator', 'agent'],
          component: PushDevicesHome,
        },
      ],
    },
  ],
};
