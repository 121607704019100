<template>
  <div>
    <h5 v-if="notificationData.isNewNotification">New notification</h5>
    <div class="iphone-preview">
      <div ref="preview" class="notification_preview">
        <img class="logo" src="https://owlio.app/logo192.png" />
        <div class="text">
          <div class="title">
            <input
              v-model.trim="notificationData.notificationTitle"
              :disabled="!notificationData.isNewNotification"
              type="text"
              placeholder="Introduce the title"
            />
          </div>

          <div class="subtitle">
            <input
              v-model.trim="notificationData.notificationSubtitle"
              :disabled="!notificationData.isNewNotification"
              type="text"
              placeholder="Introduce the subtitle"
            />
          </div>

          <div class="message">
            <input
              v-model.trim="notificationData.notificationMessage"
              :disabled="!notificationData.isNewNotification"
              type="text"
              placeholder="Introduce the message"
            />
          </div>
        </div>

        <div class="time">now</div>
      </div>

      <message-preview :notification-data="notificationData" />
    </div>
  </div>
</template>

<script>
import MessagePreview from './MessagePreview.vue';
export default {
  components: {
    MessagePreview,
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: 'San Francisco';
  src: url('/fonts/san-francisco-regular.woff');
}
.notification_preview {
  background-color: #f3f2f7;
  display: flex;
  flex-direction: row;
  height: 108px;
  width: 70%;
  margin: 15%;
  border-radius: 20px;
  margin-bottom: 20px;
  justify-content: space-evenly;
}

.notification_preview .text {
  font-family: 'San Francisco';
  margin-top: 1.8px;
  margin-left: 13.5px;
  width: 65%;
}
.notification_preview .title input,
.notification_preview .subtitle input {
  font-family: 'San Francisco';
  font-size: 16.65pt;
  font-weight: bold;
  height: auto;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
}

.notification_preview .title input:focus,
.notification_preview .subtitle input:focus,
.notification_preview .message input:focus {
  outline: none !important;
}

.notification_preview .message input {
  font-family: 'San Francisco';
  font-size: 16.2pt;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  height: auto;
}

.notification_preview .logo {
  width: 51.75px;
  height: 51.75px;
  align-self: center;
  border-radius: 13.5px;
}

.notification_preview .time {
  font-family: 'San Francisco';
  font-size: 13.5pt;
}

.iphone-preview {
  background-image: url('/assets/images/push-editor/iphone-4x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding-bottom: calc(100% / 1.68);
  padding-top: 20px;
}
</style>
