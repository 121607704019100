<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('PUSH_DEVICES_MGMT.HEADER_BTN_TXT') }}
    </woot-button>

    <!-- List Push Devices -->
    <div class="row">
      <div class="small-8 columns with-right-space custom-table-container">
        <p
          v-if="!uiFlags.fetchingList && !records.length"
          class="no-items-error-message"
        >
          {{ $t('PUSH_DEVICES_MGMT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.fetchingList"
          :message="$t('PUSH_DEVICES_MGMT.LOADING')"
        />

        <table
          v-if="!uiFlags.fetchingList && records.length"
          class="woot-table custom-table"
        >
          <thead class="sticky-thead">
            <!-- Header -->
            <th
              v-for="thHeader in $t('PUSH_DEVICES_MGMT.LIST.TABLE_HEADER')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(pushDeviceItem, index) in records"
              :key="pushDeviceItem.key"
            >
              <!-- Token  -->
              <td class="short-code">
                {{ pushDeviceItem.push_devices_token }}
              </td>
              <!-- Name -->
              <td class="wrap-break-words">
                {{ pushDeviceItem.push_devices_name }}
              </td>
              <!-- Version -->
              <td class="short-code">
                {{ pushDeviceItem.push_devices_version }}
              </td>
              <!-- Model -->
              <td class="short-code">
                {{ pushDeviceItem.push_devices_model }}
              </td>
              <!-- Bundle -->
              <td class="short-code">
                {{ pushDeviceItem.push_devices_bundle }}
              </td>
              <!-- Action Buttons -->
              <td class="button-wrapper">
                <woot-button
                  v-tooltip.top="$t('PUSH_DEVICES_MGMT.EDIT.BUTTON_TEXT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  icon="edit"
                  @click="openEditPopup(pushDeviceItem)"
                />
                <woot-button
                  v-tooltip.top="$t('PUSH_DEVICES_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  :is-loading="loading[pushDeviceItem.id]"
                  @click="openDeletePopup(pushDeviceItem, index)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="small-4 columns">
        <span v-dompurify-html="$t('PUSH_DEVICES_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-push-devices :on-close="hideAddPopup" />
    </woot-modal>

    <!-- Edit Push Devices -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-push-devices
        v-if="showEditPopup"
        :id="selectedPushDevice.id"
        :ed-token="selectedPushDevice.push_devices_token"
        :ed-name="selectedPushDevice.push_devices_name"
        :ed-version="selectedPushDevice.push_devices_version"
        :ed-model="selectedPushDevice.push_devices_model"
        :ed-bundle="selectedPushDevice.push_devices_bundle"
        :on-close="hideEditPopup"
      />
    </woot-modal>

    <!-- Delete Push Devices -->
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('PUSH_DEVICES_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('PUSH_DEVICES_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddPushDevices from './AddPushDevices';
import EditPushDevices from './EditPushDevices';

export default {
  components: {
    AddPushDevices,
    EditPushDevices,
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedPushDevice: {},
      pushDevicesAPI: {
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      records: 'getAllPushDevices',
      uiFlags: 'getPushUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('PUSH_DEVICES_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedPushDevice.push_devices_token
      }`;
    },
    deleteRejectText() {
      return `${this.$t('PUSH_DEVICES_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedPushDevice.push_devices_token
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedPushDevice.push_devices_token}?`;
    },
  },
  mounted() {
    // Fetch API Call
    this.$store.dispatch('getPushDevices');
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.selectedPushDevice.id] = false;
      this.selectedPushDevice = {};
      // Show message
      this.pushDevicesAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Modal Functions
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedPushDevice = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Modal Functions
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedPushDevice = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedPushDevice.id] = true;
      this.closeDeletePopup();
      this.deletePushDevices(this.selectedPushDevice.id);
    },
    deletePushDevices(id) {
      this.$store
        .dispatch('deletePushDevices', id)
        .then(() => {
          this.showAlert(
            this.$t('PUSH_DEVICES_MGMT.DELETE.API.SUCCESS_MESSAGE')
          );
        })
        .catch(() => {
          this.showAlert(this.$t('PUSH_DEVICES_MGMT.DELETE.API.ERROR_MESSAGE'));
        });
    },
  },
};
</script>
<style scoped>
.short-code {
  width: 14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrap-break-words {
  word-break: break-all;
  white-space: normal;
}

.custom-table {
  table-layout: fixed;
}

.custom-table-container {
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sticky-thead {
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Set the background color you prefer */
  z-index: 1; /* Ensure it appears above the table body */
}
</style>
