<template>
  <div
    ref="square"
    class="draggable-resizable-square"
    :style="{
      transform: `translate(${notificationData.left}px, ${notificationData.top}px)`,
      width: notificationData.width + 'px',
      height: notificationData.height + 'px',
    }"
    @mousedown="startDrag"
  >
    <div>
      <div class="info-container">
        <img
          class="logo fixed-width-child"
          src="https://owlio.app/logo192.png"
        />
        <div class="text-container">
          <div class="title">{{ notificationData.notificationTitle }}</div>
          <div class="subtitle">
            {{ notificationData.notificationSubtitle }}
          </div>
          <div class="message">{{ notificationData.notificationMessage }}</div>
        </div>
      </div>
      <div class="ok-btn">Ok</div>
    </div>
    <div class="resize-handle" @mousedown="startResize">
      <woot-button
        icon="resize-large"
        size="tiny"
        variant="smooth"
        color-scheme="secondary"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      status: 'stale',
      isResizing: false,
      startX: 0,
      startY: 0,
    };
  },
  methods: {
    startDrag(event) {
      if (!this.notificationData.isNewNotification) return;
      if (this.status !== 'stale') return;
      this.status = 'isDragging';
      this.startX = event.clientX - this.notificationData.left;
      this.startY = event.clientY - this.notificationData.top;
      window.addEventListener('mousemove', this.onDrag);
      window.addEventListener('mouseup', this.stopDrag);
    },
    onDrag(event) {
      if (!this.notificationData.isNewNotification) return;
      if (this.status === 'isDragging') {
        const parentElement = this.$el.parentNode;
        const maxX = parentElement.clientWidth - this.$el.clientWidth;
        const maxY = parentElement.clientHeight - this.$el.clientHeight;
        const marginSide = parentElement.clientWidth * 0.125;
        const marginBottom = parentElement.clientHeight * 0.45;
        this.notificationData.left = Math.min(
          maxX - marginSide,
          Math.max(marginSide, event.clientX - this.startX)
        );
        this.notificationData.top = Math.min(
          maxY - marginBottom,
          Math.max(0, event.clientY - this.startY)
        );
      }
    },
    stopDrag() {
      if (!this.notificationData.isNewNotification) return;
      this.status = 'stale';
      window.removeEventListener('mousemove', this.onDrag);
      window.removeEventListener('mouseup', this.stopDrag);
    },
    startResize(event) {
      if (!this.notificationData.isNewNotification) return;
      if (this.status !== 'stale') return;
      this.status = 'isResizing';
      this.startX = event.clientX - this.notificationData.width;
      this.startY = event.clientY - this.notificationData.height;
      window.addEventListener('mousemove', this.onResize);
      window.addEventListener('mouseup', this.stopResize);
    },
    onResize(event) {
      if (!this.notificationData.isNewNotification) return;
      if (this.status === 'isResizing') {
        const newWidth = event.clientX - this.startX;
        const parentElementWidth = this.$el.parentNode.clientWidth * 0.7;

        // Check if the new width exceeds the parent's width
        if (newWidth <= parentElementWidth && newWidth >= 120) {
          this.notificationData.width = newWidth;
        }

        const newHeight = event.clientY - this.startY;
        if (newHeight >= 110) {
          this.notificationData.height = newHeight;
        }
      }
    },
    stopResize() {
      if (!this.notificationData.isNewNotification) return;
      this.status = 'stale';
      window.removeEventListener('mousemove', this.onResize);
      window.removeEventListener('mouseup', this.stopResize);
    },
  },
};
</script>
<style scoped>
.draggable-resizable-square {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #f3f2f7;
  cursor: move;
  border-radius: 10px;
}

.resize-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: se-resize;
}

.title,
.subtitle {
  font-weight: bold;
}

.title,
.subtitle,
.message,
.ok-btn {
  font-family: 'San Francisco';
  text-align: center;
}

.ok-btn {
  bottom: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.info-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 90px;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  margin-left: 10px;
}

.fixed-width-child {
  flex: 0 0 50px; /* This sets a fixed width of 50px */
}

.text-container {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
