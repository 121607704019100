<template>
  <div class="notification-container">
    <div>
      <template v-if="optionActive === -1">
        <empty-state class="push-view" :is-on-expanded-layout="false" />
      </template>
      <template v-else>
        <div class="push-view">
          <push-notification-preview
            ref="notificationPreview"
            :notification-data="notificationData"
          />
          <push-device-list
            ref="pushDeviceList"
            :is-open="isDeviceListOpen"
            :bundle="bundle"
            @isDeviceListOpen="updateIsDeviceListOpen"
          />
          <div class="push-devices-footer">
            <woot-button
              variant="smooth"
              color-scheme="secondary"
              icon="settings"
              @click="openAdvancedOptions"
            />
            <woot-button
              variant="smooth"
              color-scheme="secondary"
              icon="person"
              @click="
                () => {
                  updateIsDeviceListOpen(true);
                }
              "
            />
            <woot-button
              v-if="notificationData.isNewNotification"
              variant="flat"
              color-scheme="primary"
              icon="send-clock"
              @click="saveNotifications"
            />
          </div>
          <more-options-push
            ref="moreOptionsPush"
            :notification-data="notificationData"
          />
        </div>
      </template>
    </div>
    <div v-if="isLoadingContent" class="progress-notification-container">
      <loading-state />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import EmptyState from '../EmptyState.vue';
import LoadingState from '../../LoadingState.vue';
import MoreOptionsPush from './MoreOptionsPush.vue';
import PushNotificationPreview from './PushNotificationPreview.vue';
import PushDeviceList from './PushDeviceList.vue';

export default {
  components: {
    EmptyState,
    LoadingState,
    MoreOptionsPush,
    PushNotificationPreview,
    PushDeviceList,
  },
  props: {
    inboxId: {
      type: [Number, String],
      default: '',
      required: false,
    },
    isContactPanelOpen: {
      type: Boolean,
      default: true,
    },
    isOnExpandedLayout: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filteredDevices: [],
      isDeviceListOpen: false,
      currentPushChannel: {},
      bundle: '-',
      activeIndex: 0,
      notificationData: {
        expirationTime: 60,
        priority: 5,
        badge: 0,
        alertType: 'alert',
        sound: 'default',
        optionActive: 0,
        isNewNotification: false,
        notificationTitle: '',
        notificationSubtitle: '',
        notificationMessage: '',
        left: 100,
        top: 100,
        width: 200,
        height: 120,
      },

      addNotification: {
        showLoading: false,
        showSendingPushNotifications: false,
        message: '',
      },
      bar: { value: 0 },
      optionActive: -1,
      isLoadingContent: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      getAllPushDevices: 'getAllPushDevices',
      uiFlags: 'getPushNotificationsUIFlags',
      getPushChannels: 'getPushChannels',
    }),
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
  },
  watch: {
    inboxId(newInboxId) {
      this.$store.dispatch('inboxAssignableAgents/fetch', [newInboxId]);
      this.fetchPushChannel(this.inbox.channel_id);
    },
  },
  mounted() {
    this.fetchPushChannel(this.inbox.channel_id);
  },
  methods: {
    openAdvancedOptions() {
      this.$refs.moreOptionsPush.toogleOptions();
    },
    async fetchPushChannel(searchKey) {
      this.currentPushChannel = {};
      try {
        await this.$store.dispatch('getPushChannels', {
          searchKey: searchKey,
        });
        this.currentPushChannel = this.$store.getters.getPushChannels[0];
        this.bundle = this.currentPushChannel.push_channels_bundle;
      } catch (error) {
        this.currentPushChannel = {};
      }
    },
    fetchDevices() {
      // Trigger the child component's method to send the devices data
      this.filteredDevices = this.$refs.pushDeviceList.devices
        .filter(device => device.checked === true)
        .map(device => device.push_devices_token);
    },
    sendNotifications() {
      this.onLoadingContentStart();
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          notification: {
            title: this.notificationData.notificationTitle,
            subtitle: this.notificationData.notificationSubtitle,
            body: this.notificationData.notificationMessage,
            expiration: this.notificationData.expirationTime,
            badge: this.notificationData.badge,
            priority: this.notificationData.priority,
            sound: this.notificationData.sound,
            topic: this.currentPushChannel.push_channels_bundle,
            width: this.notificationData.width,
            height: this.notificationData.height,
            left: this.notificationData.left,
            top: this.notificationData.top,
          },
          devices: this.filteredDevices,
          keyId: this.currentPushChannel.push_channels_keyId,
          teamId: this.currentPushChannel.push_channels_teamId,
          key: this.currentPushChannel.push_channels_key,
        }).replace(/\n/g, '\\\\n'),
      };

      fetch(this.currentPushChannel.push_channels_server_path, requestOptions)
        // eslint-disable-next-line consistent-return
        .then(async response => {
          const data = await response.json();
          this.onLoadingContentEnd();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            this.showAlertSend(error);
            return Promise.reject(error);
          }
        })
        .catch(error => {
          this.onLoadingContentEnd();
          this.showAlertSend(error);
        });
    },
    createNewNotification() {
      this.$set(this, 'optionActive', 0); // Use $set to update optionActive
      this.notificationData.isNewNotification = true;
      this.resetForm();
    },
    showNotification(id) {
      this.resetForm();
      this.notificationData.isNewNotification = false;
      this.optionActive = id;
      this.addNotification.showLoading = true;
      const notification = this.$store.getters.getPushNotificationById(id);
      this.notificationData.notificationTitle =
        notification.push_notifications_title;
      this.notificationData.notificationSubtitle =
        notification.push_notifications_subtitle;
      this.notificationData.notificationMessage =
        notification.push_notifications_message;
      this.notificationData.expirationTime =
        notification.push_notifications_expiration;
      this.notificationData.priority = notification.push_notifications_priority;
      this.notificationData.badge = notification.push_notifications_badge;
      this.notificationData.alertType = notification.push_notifications_type;
      this.notificationData.sound = notification.push_notifications_sound;
      this.notificationData.left = notification.left;
      this.notificationData.top = notification.top;
      this.notificationData.width = notification.width;
      this.notificationData.height = notification.height;
    },
    createNotificationData() {
      let notification = {
        push_notifications_title: this.notificationData.notificationTitle,
        push_notifications_subtitle: this.notificationData.notificationSubtitle,
        push_notifications_message: this.notificationData.notificationMessage,
        push_notifications_expiration: this.notificationData.expirationTime,
        push_notifications_priority: this.notificationData.priority,
        push_notifications_badge: this.notificationData.badge,
        push_notifications_type: this.notificationData.alertType,
        push_notifications_sound: this.notificationData.sound,
        left: this.notificationData.left,
        top: this.notificationData.top,
        width: this.notificationData.width,
        height: this.notificationData.height,
        channel_id: this.inbox.channel_id,
      };
      return notification;
    },
    async saveNotifications() {
      this.fetchDevices();
      this.fetchPushChannel();
      let notification = this.createNotificationData();
      try {
        await this.$store
          .dispatch('createPushNotifications', notification)
          .then(() => {
            this.sendNotifications();
            this.showAlertSend(
              this.$t('PUSH_NOTIFICATIONS_MGMT.ADD.API.SUCCESS_MESSAGE')
            );
          })
          .catch(error => {
            this.showAlertSend(error);
          });
      } catch (error) {
        this.currentPushChannel = {};
        this.showAlertSend(error);
      }
    },
    resetForm() {
      this.notificationData.expirationTime = 60;
      this.notificationData.priority = 5;
      this.notificationData.badge = 0;
      this.notificationData.alertType = 'alert';
      this.notificationData.sound = 'default';
      this.notificationData.optionActive = 0;
      this.notificationData.isNewNotification = true;
      this.notificationData.notificationTitle = '';
      this.notificationData.notificationSubtitle = '';
      this.notificationData.notificationMessage = '';
      this.notificationData.left = 100;
      this.notificationData.top = 100;
      this.notificationData.width = 200;
      this.notificationData.height = 120;
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    onDashboardAppTabChange(index) {
      this.activeIndex = index;
    },
    showAlertSend(msg) {
      bus.$emit('newToastMessage', msg);
    },
    onLoadingContentStart() {
      this.isLoadingContent = true;
    },
    onLoadingContentEnd() {
      this.isLoadingContent = false;
      this.resetForm();
    },
    updateIsDeviceListOpen(newValue) {
      this.isDeviceListOpen = newValue;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/woot';

.conversation-details-wrap {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  background: var(--color-background-light);

  &.with-border-right {
    border-right: 1px solid var(--color-border);
  }
}

.dashboard-app--tabs {
  background: var(--white);
  margin-top: -1px;
  min-height: var(--dashboard-app-tabs-height);
}

.messages-and-sidebar {
  display: flex;
  background: var(--color-background-light);
  margin: 0;
  height: 100%;
  min-height: 0;
}

.conversation-sidebar-wrap {
  border-right: 1px solid var(--color-border);
  height: auto;
  flex: 0 0;
  z-index: var(--z-index-low);
  overflow: auto;
  background: white;
  flex-basis: 100%;

  @include breakpoint(medium up) {
    flex-basis: 28rem;
  }

  @include breakpoint(large up) {
    flex-basis: 30em;
  }

  @include breakpoint(xlarge up) {
    flex-basis: 31em;
  }

  @include breakpoint(xxlarge up) {
    flex-basis: 33rem;
  }

  @include breakpoint(xxxlarge up) {
    flex-basis: 40rem;
  }

  &::v-deep .contact--panel {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

.notification-container {
  align-items: center;
  justify-content: center;
  flex: 1;
  display: flex;
}
.push-devices-checkbox {
  margin: 0;
}
.push-devices-button-wrapper {
  text-align: center !important;
}

.push {
  height: 200px;
  border: 2px solid black;
  overflow: auto;
}

.progress-notification-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  justify-content: center;
  display: flex;
  z-index: 9997;
  align-items: center;
}

.progress-notification {
  align-self: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  font-size: 2em;
}

.bar-notifications {
  width: 50vw;
  min-width: 320px;
  height: 25px;
}

.push-view {
  max-width: 512px;
  width: 80vw;
}

.push-devices-footer {
  width: 100%;
  text-align: center;
}
</style>
