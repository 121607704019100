<template>
  <div
    class="more-options"
    :style="{
      display: displayed,
    }"
  >
    <div class="line">
      <div>
        <label>Expiration time</label>
        <input
          v-model.trim="notificationData.expirationTime"
          :disabled="!notificationData.isNewNotification"
          type="number"
          placeholder="Expiration time in minutes"
          :min="minExpirationTime"
          :max="maxExpirationTime"
        />
      </div>
      <div>
        <label>Priority</label>
        <input
          v-model.trim="notificationData.priority"
          :disabled="!notificationData.isNewNotification"
          type="number"
          placeholder="Priority"
          :min="minPriority"
          :max="maxPriority"
        />
      </div>
      <div>
        <label>Badge</label>
        <input
          v-model.trim="notificationData.badge"
          :disabled="true"
          type="number"
          placeholder="Badge"
          :min="minBadge"
          :max="maxBadge"
        />
      </div>
    </div>
    <div class="line">
      <div>
        <label>Alert type</label>
        <select
          id="type"
          v-model.trim="notificationData.alertType"
          :disabled="!notificationData.isNewNotification"
        >
          <option value="alert">Alert</option>
          <option value="background">Background</option>
        </select>
      </div>
      <div>
        <label>Sound</label>
        <select
          id="sound"
          v-model.trim="notificationData.sound"
          :disabled="true"
        >
          <option value="default">Default</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => ({
        expirationTime: '',
        priority: '',
        badge: '',
        alertType: 'alert',
        sound: 'default',
        disabled: true, // Default to disabled
        minExpirationTime: 1,
        maxExpirationTime: 1440,
        minPriority: 5,
        maxPriority: 10,
        minBadge: 0,
        maxBadge: 10,
      }),
    },
  },
  data() {
    return {
      displayed: 'none',
    };
  },
  computed: {
    expirationTime: {
      get() {
        return this.value.expirationTime;
      },
      set(value) {
        this.$emit('input', { ...this.value, expirationTime: value });
      },
    },
    priority: {
      get() {
        return this.value.priority;
      },
      set(value) {
        this.$emit('input', { ...this.value, priority: value });
      },
    },
    badge: {
      get() {
        return this.value.badge;
      },
      set(value) {
        this.$emit('input', { ...this.value, badge: value });
      },
    },
    alertType: {
      get() {
        return this.value.alertType;
      },
      set(value) {
        this.$emit('input', { ...this.value, alertType: value });
      },
    },
    sound: {
      get() {
        return this.value.sound;
      },
      set(value) {
        this.$emit('input', { ...this.value, sound: value });
      },
    },
    disabled: {
      get() {
        return this.value.disabled;
      },
      set(value) {
        this.$emit('input', { ...this.value, disabled: value });
      },
    },
    minExpirationTime: {
      get() {
        return this.value.minExpirationTime;
      },
    },
    maxExpirationTime: {
      get() {
        return this.value.maxExpirationTime;
      },
    },
    minPriority: {
      get() {
        return this.value.minPriority;
      },
    },
    maxPriority: {
      get() {
        return this.value.maxPriority;
      },
    },
    minBadge: {
      get() {
        return this.value.minBadge;
      },
    },
    maxBadge: {
      get() {
        return this.value.maxBadge;
      },
    },
  },
  methods: {
    toogleOptions() {
      this.displayed = this.displayed === 'none' ? 'flex' : 'none';
    },
  },
};
</script>
<style lang="scss" scoped>
.more-options {
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.more-options .line {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.more-options .line div {
  flex: 1;
}

.btn-container {
  width: 100%;
  text-align: right; /* Align content to the right */
}
</style>
