<template>
  <div
    class="conversation"
    :class="{
      active: isActiveChat,
      'has-inbox-name': showInboxName,
      'conversation-selected': selected,
    }"
    @click="cardClick(chat)"
  >
    <label class="checkbox-wrapper" @click.stop>
      <woot-button
        color-scheme="alert"
        icon="delete"
        @click="deleteConversation(notificationId)"
      />
    </label>
    <div class="conversation--details columns">
      <h4 class="conversation--user">
        {{ title }}
      </h4>
      <p class="conversation--message">
        <span v-if="message">
          {{ message }}
        </span>
        <span v-else>
          -- no message --
        </span>
      </p>
      <div class="conversation--meta">
        <span class="timestamp">
          <time-ago
            :last-activity-timestamp="parsedDate"
            :created-at-timestamp="parsedDate"
          />
        </span>
      </div>
    </div>
    <woot-confirm-modal
      ref="confirmDialog"
      title="Delete push notification"
      description="Are you sure you want to delete this notification?"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import conversationMixin from '../../../../mixins/conversations';
import timeMixin from '../../../../mixins/time';
import inboxMixin from 'shared/mixins/inboxMixin';
import alertMixin from 'shared/mixins/alertMixin';
import TimeAgo from 'dashboard/components/ui/TimeAgo';

export default {
  components: {
    TimeAgo,
  },

  mixins: [
    inboxMixin,
    timeMixin,
    conversationMixin,
    messageFormatterMixin,
    alertMixin,
  ],
  props: {
    handleClearNotification: {
      type: Function,
      default: () => {},
    },
    chat: {
      type: Object,
      default: () => {},
    },
    hideInboxName: {
      type: Boolean,
      default: false,
    },

    conversationType: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    dateCreated: {
      type: String,
      default: '',
    },
    notificationId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showContextMenu: false,
      contextMenu: {
        x: null,
        y: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentNotification: 'getPushNotificationById',
      inboxesList: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
    }),
    isActiveChat() {
      return this.currentNotification.id === this.notificationId;
    },
    isInboxNameVisible() {
      return !this.activeInbox;
    },
    inbox() {
      const { inbox_id: inboxId } = this.chat;
      const stateInbox = this.$store.getters['inboxes/getInbox'](inboxId);
      return stateInbox;
    },
    showInboxName() {
      return (
        !this.hideInboxName &&
        this.isInboxNameVisible &&
        this.inboxesList.length > 1
      );
    },
    inboxName() {
      const stateInbox = this.inbox;
      return stateInbox.name || '';
    },
    parsedDate() {
      let formattedDate = new Date(this.dateCreated);
      return formattedDate.getTime() / 1000;
    },
  },
  methods: {
    cardClick() {
      this.$emit('showNotification', this.notificationId);
    },
    async deleteConversation(id) {
      const ok = await this.$refs.confirmDialog.showConfirmation();
      if (ok) {
        this.$store
          .dispatch('deletePushNotifications', id)
          .then(() => {
            this.handleClearNotification();
            this.showAlert('Push notification deleted');
          })
          .catch(error => {
            const errorMessage = error?.message;
            this.showAlert(errorMessage);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation {
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    background: var(--color-background-light);
  }

  &::v-deep .user-thumbnail-box {
    margin-top: var(--space-normal);
  }
}

.conversation-selected {
  background: var(--color-background-light);
}

.has-inbox-name {
  &::v-deep .user-thumbnail-box {
    margin-top: var(--space-large);
  }
  .checkbox-wrapper {
    margin-top: var(--space-large);
  }
  .conversation--meta {
    margin-top: var(--space-normal);
  }
}

.conversation--details {
  padding-left: 10px;
  padding-right: 20px;
  .conversation--user {
    font-size: 12pt !important;
    padding-top: var(--space-micro);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 60%;
  }

  .conversation--message {
    font-size: 10pt !important;
  }
}

.last-message-icon {
  color: var(--s-600);
}

.conversation--metadata {
  display: flex;
  justify-content: space-between;

  .label {
    background: none;
    color: var(--s-500);
    font-size: var(--font-size-mini);
    font-weight: var(--font-weight-medium);
    line-height: var(--space-slab);
    padding: var(--space-micro) 0 var(--space-micro) 0;
  }
}

.message--attachment-icon {
  margin-top: var(--space-minus-micro);
  vertical-align: middle;
}

.checkbox-wrapper {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-top: var(--space-normal);
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    background-color: var(--w-100);
  }

  input[type='checkbox'] {
    margin: var(--space-zero);
    cursor: pointer;
  }
}

.timestamp {
  text-align: right;
}
</style>
