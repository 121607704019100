<template>
  <div class="beta-tester-container beta-tester-bg">
    <div class="beta-tester-view">
      <span class="page-title">Beta Testers</span>
      <woot-tabs :index="selectedTabIndex" @change="onTabChange">
        <woot-tabs-item name="General Information" :show-badge="false" />
        <woot-tabs-item name="Groups" :show-badge="false" />
        <woot-tabs-item name="Builds" :show-badge="false" />
      </woot-tabs>
      <div class="tab-content overflow-auto">
        <div v-if="selectedTabIndex == 0" class="betatester-info-container">
          <beta-tester-basic-info
            :beta-tester-data="betaTesterData"
            :contact-info="contactInfo"
            @closeModal="onClose"
            @deleteBetaTester="deleteBetaTester"
            @updateLabel="onUpdateLabels"
            @inviteToBetaTest="createNewBetaTesterFromInvitation"
            @refreshInfo="loadBasicInformation"
          />
        </div>
        <div v-if="selectedTabIndex == 1" class="betatester-info-container">
          <beta-tester-groups
            :beta-tester-data="betaTesterData"
            @closeModal="onClose"
            @removeBetaTesterFromGroup="removeBetaTesterFromGroup"
            @inviteBetaTesterToGroup="inviteBetaTesterToGroup"
          />
        </div>
        <div v-if="selectedTabIndex == 2" class="betatester-info-container">
          <beta-tester-builds />
        </div>
      </div>
    </div>
    <div v-if="isLoadingModal" class="betatester-modal-loading-container">
      <loading-state />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingState from '../../../../components/widgets/LoadingState.vue';
import BetaTesterBasicInfo from './betaTester/BetaTesterBasicInfo.vue';
import BetaTesterGroups from './betaTester/BetaTesterGroups.vue';
import BetaTesterBuilds from './betaTester/BetaTesterBuilds.vue';

const urlService = 'https://apn.owlio.app/';

export default {
  components: {
    LoadingState,
    BetaTesterBasicInfo,
    BetaTesterGroups,
    BetaTesterBuilds,
  },
  props: {
    contactInfo: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onUpdateLabels: {
      type: Function,
      default: () => {},
    },
    onCreateNewBetaTester: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedTabIndex: 0,
      allInformation: {},
      builds: [],
      groups: [],
      buildBetaDetails: [],
      preReleaseVersions: [],
      betaTester: {},
      betaGroupsInvited: null,
      isNewGroupModalOpen: false,
      isModalOpen: false,
      newGroupName: '',
      isLoadingModal: false,
      isGroupSettingsModalOpen: false,
      groupSettings: null,
      betaTesters: [],
      betaTesterData: {},
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  mounted() {
    this.loadBasicInformation();
  },
  methods: {
    onTabChange(e) {
      this.selectedTabIndex = e;
    },
    async loadAllTesters() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      const response = await fetch(urlService + 'betaTesters', requestOptions);
      const data = await response.json();
      this.betaTesters = data.result.data;
    },
    async loadTester(email) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email }),
      };
      const response = await fetch(urlService + 'betatester', requestOptions);
      const data = await response.json();

      this.betaTesterData = data;

      if (data.result.data.length > 0) {
        this.betaTester = data.result.data[0];
      } else this.betaTester = {};
      this.betaGroupsInvited = data.result.included.betaGroups;
    },
    async loadBasicInformation() {
      this.onLoadingStart();
      await this.loadTester(this.contactInfo.email);
      this.onLoadingEnd();
    },
    async loadInformation() {
      await this.onLoadingStart();
      await this.loadTester(this.contactInfo.email);
      await this.onLoadingEnd();
    },

    createRequestOptions(type, data) {
      const requestOptions = {
        method: type,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };
      return requestOptions;
    },
    async deleteOneBetaTester(testerID) {
      let reqOptions = this.createRequestOptions('DELETE', {
        testerID: testerID,
      });
      const response = await fetch(urlService + 'betaTesters', reqOptions);
      if (response.ok) {
        const data = await response.json();
        return data;
      }
      return null;
    },
    async deleteBetaTester(testerIDs) {
      const deletePromises = testerIDs.map(testerID =>
        this.deleteOneBetaTester(testerID)
      );
      await Promise.all(deletePromises);
      this.loadTester(this.contactInfo.email);
    },
    async inviteOneBetaTesterToGroup(testerID, groupID) {
      let reqOptions = this.createRequestOptions('POST', {
        testerID: testerID,
        groupID: groupID,
      });
      const response = await fetch(urlService + 'invite', reqOptions);

      if (!response.ok) {
        bus.$emit('newToastMessage', 'Something went wrong');
        return null;
      }
      const data = await response.json();
      return data;
    },
    async inviteBetaTesterToGroup(testerIDs, groupID) {
      const deletePromises = testerIDs.map(testerID =>
        this.inviteOneBetaTesterToGroup(testerID, groupID)
      );
      await Promise.all(deletePromises);
      this.loadTester(this.contactInfo.email);
    },
    async removeOneBetaTesterFromGroup(testerID, groupID) {
      let reqOptions = this.createRequestOptions('DELETE', {
        testerID: testerID,
        groupID: groupID,
      });
      const response = await fetch(urlService + 'invite', reqOptions);
      if (!response.ok) {
        bus.$emit('newToastMessage', 'Something went wrong');
        return null;
      }
      const data = await response.json();
      return data;
    },
    async removeBetaTesterFromGroup(testerIDs, groupID) {
      const deletePromises = testerIDs.map(testerID =>
        this.removeOneBetaTesterFromGroup(testerID, groupID)
      );
      await Promise.all(deletePromises);
      this.loadTester(this.contactInfo.email);
    },
    async createNewGroup() {
      if (this.newGroupName !== '') {
        this.isLoadingModal = true;
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ name: this.newGroupName }),
        };
        await fetch(urlService + 'createGroups', requestOptions)
          .then(response => {
            if (response.ok) {
              this.isLoadingModal = false;
              this.newGroupName = '';
              bus.$emit('newToastMessage', 'Group saved successfully');
              this.closeGroupModal();
              return;
            }
            bus.$emit('newToastMessage', 'Something went wrong');
          })
          .catch(error => {
            bus.$emit('newToastMessage', error);
          });
      }
    },
    openGroupModal() {
      this.isModalOpen = true;
      this.isNewGroupModalOpen = true;
    },
    closeGroupModal() {
      this.isNewGroupModalOpen = false;
      this.isModalOpen = false;
    },
    openGroupSettings(g) {
      this.isGroupSettingsModalOpen = true;
      this.isModalOpen = true;
      this.groupSettings = g;
    },
    closeGroupSettings() {
      this.isGroupSettingsModalOpen = false;
      this.isModalOpen = false;
      this.groupSettings = null;
    },
    testersFromGroup(grp) {
      let data = grp.relationships.betaTesters.data;
      let ids = [];
      for (let i = 0; i < data.length; i += 1) {
        ids.push(data[i].id);
      }
      let groupTesters = [];
      for (let j = 0; j < this.betaTesters.length; j += 1) {
        if (ids.includes(this.betaTesters[j].id))
          groupTesters.push(this.betaTesters[j]);
      }
      return groupTesters;
    },
    onLoadingEnd() {
      this.isLoadingModal = false;
    },
    onLoadingStart() {
      this.isLoadingModal = true;
    },
    addToInvitedBetaGroups(groupID) {
      this.betaGroupsInvited[groupID] = {};
    },
    removeFromInvitedBetaGroups(groupID) {
      this.betaGroupsInvited[groupID] = null;
    },
    async createNewBetaTester() {
      this.onLoadingStart();
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.contactInfo.email,
          firstName: this.contactInfo.name,
          lastName: 'Chatwoot',
        }),
      };
      await fetch(urlService + 'createBetaTester', requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          bus.$emit('newToastMessage', 'Something went wrong');
          return null;
        })
        .then(data => {
          if (data.result !== 'Ok' && data.result.data.errors) {
            this.onLoadingEnd();
            bus.$emit('newToastMessage', data.result.data.errors);
            return;
          }
          this.loadInformation();
          bus.$emit('newToastMessage', 'Beta tester added successfully');
          this.onLoadingEnd();
        })
        .catch(error => {
          this.onLoadingEnd();
          bus.$emit('newToastMessage', error);
        });
    },
    async createNewBetaTesterFromInvitation(name, email, id) {
      await this.onLoadingStart();
      await this.onCreateNewBetaTester(name, email, id);
      await this.loadTester(this.contactInfo.email);
      await this.onLoadingEnd();
    },
  },
};
</script>

<style lang="scss" scoped>
.beta-tester-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9997;
  justify-content: center;
}
.beta-tester-bg {
  background: rgb(0, 0, 0, 0.5);
}
.beta-tester-view {
  background: white;
  align-self: center;
  padding: 30px;
  border-radius: 8px;
  min-width: 50%;
  min-height: 50%;
  max-width: 100%;
  max-height: 100%;
}

.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.betatester-info-container {
  font-size: 14pt;
  text-align: center;
}
.betatester-first-column {
  text-align: center;
}

.betatester-first-column {
  text-align: center;
}
.betatester-table-container {
  height: 40vh;
  overflow: scroll;
}
.betatester-table {
  border-collapse: unset;
}
.betatester-table-container-only-tester {
  overflow: scroll;
}
.betatester-table-container-half {
  height: 20vh;
  overflow: scroll;
}

.sticky-head {
  position: sticky;
  top: 0;
  background: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.betatester-modal {
  display: block;
  top: 50%;
  left: 50%;
  position: absolute;
  background: #ffffff;
  border-radius: 15px;
  border-color: #000;
  border-width: 1px;
  border-style: solid;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
}
.betatester-modal h6 {
  width: 100%;
  background-color: #1f93ff;
  color: #ffffff;
  background-color: #1f93ff;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  overflow: hidden;
}
.betatester-modal div input {
  padding: 5px;
  margin: 10px;
  width: calc(100% - 20px);
}
.betatester-modal-button-container {
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
}

.betatester-modal-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.betatester-modal-loading-container {
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  display: flex;
  justify-content: center;
}
.group-settings-content {
  padding: 20px;
}
.betatester-groups-column {
  overflow-x: scroll;
}
</style>
