<template>
  <woot-modal
    :show="isOpen"
    :on-close="
      () => {
        onCloseList();
      }
    "
    size="medium"
  >
    <woot-modal-header class="apn-modal-users-title">
      Select recipient devices 0.0.9
    </woot-modal-header>
    <div class="apn-users">
      <table class="woot-table push-devices-table">
        <thead>
          <tr>
            <!-- Header -->
            <th
              v-for="header in tableHeaders"
              :key="header"
              class="sticky-head tr-overflow"
            >
              {{ header }}
            </th>
            <th class="sticky-head push-devices-button-wrapper tr-overflow">
              All
              <input
                v-model="sendToAll"
                class="push-devices-checkbox"
                type="checkbox"
                :disabled="!isNewNotification"
                @click="onCheckAllPressed()"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="device in devices" :key="device.id">
            <td
              v-for="column in tableColumns"
              :key="column"
              class="push-devices-item-cell tr-overflow"
            >
              {{ device[column] }}
            </td>
            <!-- Action Buttons -->
            <td
              class="push-devices-button-wrapper push-devices-item-cell  tr-overflow"
            >
              <input
                v-model="device.checked"
                class="push-devices-checkbox"
                type="checkbox"
                :disabled="!isNewNotification"
                @click="onCheckPressed(device)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="push-devices-footer">
      <woot-button
        v-if="isNewNotification"
        variant="smooth"
        color-scheme="secondary"
        icon="repeat"
        class="margin-right-smaller"
        @click="reloadUsers"
      >
        Refresh Device List
      </woot-button>
      <woot-button
        v-if="isNewNotification"
        variant="flat"
        color-scheme="primary"
        icon="save"
        class="margin-right-smaller"
        @click="
          () => {
            onCloseList();
          }
        "
      >
        Confirm
      </woot-button>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import WootButton from '../../../../components/ui/WootButton.vue';

export default {
  components: {
    WootButton,
  },
  props: {
    isNewNotification: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    bundle: {
      type: String,
      default: '-',
    },
  },
  data() {
    return {
      sendToAll: true,
      devices: [],
      tableHeaders: ['Token', 'Name', 'Version', 'Model', 'Bundle'],
      tableColumns: [
        'push_devices_token',
        'push_devices_name',
        'push_devices_version',
        'push_devices_model',
        'push_devices_bundle',
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPushDevices: 'getPushDevices',
      uiFlags: 'getPushNotificationsUIFlags',
    }),
  },
  watch: {
    bundle() {
      this.reloadUsers();
    },
  },
  mounted() {
    this.reloadUsers();
  },
  methods: {
    async fetchPushDevices(searchKey) {
      this.devices = [];
      try {
        await this.$store.dispatch('getPushDevices', {
          searchKey: searchKey,
        });
        this.devices = this.$store.getters.getAllPushDevices;
        this.selectAllDevices();
      } catch (error) {
        this.devices = [];
      }
    },
    onCheckPressed(item) {
      if (item.checked) this.sendToAll = false;
    },
    onCheckAllPressed() {
      for (let i = 0; i < this.devices.length; i += 1) {
        this.devices[i].checked = !this.sendToAll;
      }
      this.sendToAll = !this.sendToAll;
    },
    selectAllDevices() {
      for (let i = 0; i < this.devices.length; i += 1) {
        this.devices[i].checked = true;
      }
      this.sendToAll = true;
    },
    reloadUsers() {
      this.fetchPushDevices(this.bundle);
    },
    onCloseList() {
      this.$emit('isDeviceListOpen', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.apn-users {
  max-height: calc(50vh - 110px);
  overflow: auto;
}
.sticky-head {
  position: sticky;
  top: 0;
  background: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.push-devices-footer {
  text-align: end;
  height: 40px;
}

.apn-modal-users-title {
  background: #1f93ff;
  color: white;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
}
.tr-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 4vw;
  text-align: center;
}
</style>
