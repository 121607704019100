import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import PushNotificationsAPI from '../../api/pushNotifications';

const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
    fetchingItem: false,
    creatingItem: false,
    updatingItem: false,
    deletingItem: false,
  },
};

const getters = {
  getPushNotifications(_state) {
    return _state.records;
  },
  getPushNotificationsUIFlags(_state) {
    return _state.uiFlags;
  },
  getPushNotificationById: _state => notificationId => {
    return _state.records.find(value => value.id === Number(notificationId));
  },
};

const actions = {
  getPushNotifications: async function getPushNotifications(
    { commit },
    { searchKey } = {}
  ) {
    commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
      fetchingList: true,
    });
    try {
      const response = await PushNotificationsAPI.get({ searchKey });
      commit(types.default.SET_PUSH_NOTIFICATIONS, response.data);
      commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
        fetchingList: false,
      });
    } catch (error) {
      commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
        fetchingList: false,
      });
    }
  },

  createPushNotifications: async function createPushNotifications(
    { commit },
    pushNotificationsObj
  ) {
    commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
      creatingItem: true,
    });
    try {
      const response = await PushNotificationsAPI.create(pushNotificationsObj);
      commit(types.default.ADD_PUSH_NOTIFICATIONS, response.data);
      commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
        creatingItem: false,
      });
    } catch (error) {
      commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
        creatingItem: false,
      });
    }
  },

  updatePushNotifications: async function updatePushNotifications(
    { commit },
    { id, ...updateObj }
  ) {
    commit(types.default.SET_PUSH_DEVICES_UI_FLAG, { updatingItem: true });
    try {
      const response = await PushNotificationsAPI.update(id, updateObj);
      commit(types.default.EDIT_PUSH_NOTIFICATIONS, response.data);
      commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
        updatingItem: false,
      });
    } catch (error) {
      commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
        updatingItem: false,
      });
    }
  },

  deletePushNotifications: async function deletePushNotifications(
    { commit },
    id
  ) {
    commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
      deletingItem: true,
    });
    try {
      await PushNotificationsAPI.delete(id);
      commit(types.default.DELETE_PUSH_NOTIFICATIONS, id);
      commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
        deletingItem: true,
      });
    } catch (error) {
      commit(types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG, {
        deletingItem: true,
      });
    }
  },
};

const mutations = {
  [types.default.SET_PUSH_NOTIFICATIONS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_PUSH_NOTIFICATIONS]: MutationHelpers.set,
  [types.default.ADD_PUSH_NOTIFICATIONS]: MutationHelpers.create,
  [types.default.EDIT_PUSH_NOTIFICATIONS]: MutationHelpers.update,
  [types.default.DELETE_PUSH_NOTIFICATIONS]: MutationHelpers.destroy,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
