<template>
  <div class="betatester-info-container">
    <h5>Builds available</h5>
    <div v-if="status === 'withData'">
      <div class="betatester-table-container-builds">
        <table>
          <thead>
            <tr>
              <th class="betatester-first-column sticky-head">Version</th>
              <th class="betatester-first-column sticky-head">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="build in builds" :key="build.id">
              <td class="betatester-first-column">
                {{
                  preReleaseVersions[
                    build.relationships.preReleaseVersion.data.id
                  ].attributes.version
                }}.{{ build.attributes.version }}
              </td>
              <td class="betatester-second-column">
                {{ buildBetaDetails[build.id].attributes.externalBuildState }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <woot-button
        variant="flat"
        size="expanded"
        icon="repeat"
        @click="loadBuilds"
      >
        Refresh
      </woot-button>
    </div>
    <div
      v-else-if="status === 'empty'"
      class="betatester-table-container-builds"
    >
      <thead>
        <tr>
          <th class="betatester-first-column sticky-head">
            There are not builds available
          </th>
        </tr>
      </thead>
    </div>
    <div v-else class="betatester-table-container-builds">
      <thead>
        <tr>
          <th class="betatester-first-column sticky-head">
            Loading...
          </th>
        </tr>
      </thead>
    </div>
  </div>
</template>

<script>
const urlService = 'https://apn.owlio.app/';
export default {
  props: {},
  data() {
    return {
      builds: {},
      buildBetaDetails: {},
      preReleaseVersions: [],
      status: 'Loading',
    };
  },
  computed: {},
  mounted() {
    this.loadBuilds();
  },
  methods: {
    async loadBuilds() {
      this.status = 'Loading';
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      const response = await fetch(urlService + 'builds', requestOptions);
      const data = await response.json();

      this.builds = data.result.data;
      if (this.builds.length > 0) {
        this.status = 'withData';
        this.buildBetaDetails = data.result.included.buildBetaDetails;
        this.preReleaseVersions = data.result.included.preReleaseVersions;
      } else {
        this.status = 'empty';
      }
    },
  },
};
</script>

<style lang="scss">
.betatester-first-column {
  text-align: center;
}

.betatester-table-container-only-tester {
  overflow: scroll;
}
.single-colum {
  text-align: left;
}

.betatester-table-container-builds {
  height: 50vh;
  overflow: scroll;
}
</style>
