/* global axios */
import ApiClient from '../ApiClient';

class PushChannels extends ApiClient {
  constructor() {
    super('push_channels', { accountScoped: true });
  }

  get({ searchKey }) {
    const url = searchKey ? `${this.url}?search_key=${searchKey}` : this.url;
    return axios.get(url);
  }
}

export default new PushChannels();
