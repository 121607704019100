<template>
  <div class="betatester-table-container-only-tester">
    <h5>Beta tester groups</h5>
    <div v-if="status == 'withData'">
      <div class="betatester-table-container">
        <table class="betatester-table">
          <thead>
            <tr>
              <th class="betatester-first-column sticky-head">Name</th>
              <th class="betatester-first-column sticky-head">Testers</th>
              <th class="betatester-first-column sticky-head">
                Public link
              </th>
              <th class="betatester-first-column sticky-head">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in groups" :key="group.id">
              <td
                v-if="!group.attributes.isInternalGroup"
                class="betatester-first-column"
              >
                {{ group.attributes.name }}
              </td>
              <td
                v-if="!group.attributes.isInternalGroup"
                class="betatester-first-column"
              >
                {{ group.relationships.betaTesters.meta.paging.total }}
              </td>
              <td
                v-if="!group.attributes.isInternalGroup"
                class="betatester-second-column"
              >
                <a
                  v-if="group.attributes.publicLink"
                  :href="group.attributes.publicLink"
                  target="_blank"
                >
                  Link
                </a>
              </td>
              <td
                v-if="!group.attributes.isInternalGroup"
                class="betatester-second-column"
              >
                <woot-button
                  v-if="groupsKeys[group.id] == 1"
                  variant="flat"
                  icon="subtract"
                  color-scheme="alert"
                  size="small"
                  @click="
                    $emit('removeBetaTesterFromGroup', userIDs(), group.id)
                  "
                />
                <woot-button
                  v-else
                  variant="flat"
                  icon="add"
                  size="small"
                  @click="$emit('inviteBetaTesterToGroup', userIDs(), group.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="status == 'loading'">
      <table class="betatester-table">
        <thead>
          <tr>
            <th class="betatester-first-column sticky-head">Loading...</th>
          </tr>
        </thead>
      </table>
    </div>
    <div v-else>
      <table class="betatester-table">
        <thead>
          <tr>
            <th class="betatester-first-column sticky-head">
              There are not groups availables
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div class="betatester-table-container">
      <woot-button variant="flat" icon="repeat" @click="loadGroups" />

      <woot-button
        variant="flat"
        icon="dismiss"
        color-scheme="alert"
        @click="$emit('closeModal')"
      >
        Cancel
      </woot-button>
    </div>
  </div>
</template>

<script>
const urlService = 'https://apn.owlio.app/';

export default {
  props: {
    betaTesterData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      groups: {},
      status: 'loading',
    };
  },
  computed: {
    groupsKeys() {
      let keys = {};

      let grps = this.betaTesterData.result.included.betaGroups;

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (let key in grps) {
        keys[key] = 1;
      }
      return keys;
    },
  },
  mounted() {
    this.loadGroups();
  },
  methods: {
    async loadGroups() {
      this.status = 'loading';
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const response = await fetch(urlService + 'groups', requestOptions);
      const data = await response.json();
      this.groups = data.result.data;

      if (this.groups.length > 0) {
        this.status = 'withData';
      } else {
        this.status = 'empty';
      }
    },
    userIDs() {
      const betaTestersData = this.betaTesterData.result.data;
      const ids = betaTestersData.map(item => item.id);
      return ids;
    },
  },
};
</script>

<style lang="scss">
.betatester-first-column {
  text-align: center;
}

.betatester-table-container-only-tester {
  overflow: scroll;
}
.single-colum {
  text-align: left;
}
</style>
