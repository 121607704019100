<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editPushDevices()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.token.$error }">
            {{ $t('PUSH_DEVICES_MGMT.EDIT.FORM.TOKEN.LABEL') }}
            <input
              v-model.trim="token"
              type="text"
              :placeholder="$t('PUSH_DEVICES_MGMT.EDIT.FORM.TOKEN.PLACEHOLDER')"
              @input="$v.token.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.name.$error }">
            {{ $t('PUSH_DEVICES_MGMT.EDIT.FORM.NAME.LABEL') }}
            <input
              v-model.trim="name"
              type="text"
              :placeholder="$t('PUSH_DEVICES_MGMT.EDIT.FORM.NAME.PLACEHOLDER')"
              @input="$v.name.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.version.$error }">
            {{ $t('PUSH_DEVICES_MGMT.EDIT.FORM.VERSION.LABEL') }}
            <input
              v-model.trim="version"
              type="text"
              :placeholder="
                $t('PUSH_DEVICES_MGMT.EDIT.FORM.VERSION.PLACEHOLDER')
              "
              @input="$v.version.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.model.$error }">
            {{ $t('PUSH_DEVICES_MGMT.EDIT.FORM.MODEL.LABEL') }}
            <input
              v-model.trim="model"
              type="text"
              :placeholder="$t('PUSH_DEVICES_MGMT.EDIT.FORM.MODEL.PLACEHOLDER')"
              @input="$v.model.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.bundle.$error }">
            {{ $t('PUSH_DEVICES_MGMT.EDIT.FORM.BUNDLE.LABEL') }}
            <input
              v-model.trim="bundle"
              type="text"
              :placeholder="
                $t('PUSH_DEVICES_MGMT.EDIT.FORM.BUNDLE.PLACEHOLDER')
              "
              @input="$v.bundle.$touch"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.token.$invalid ||
                  $v.name.$invalid ||
                  $v.version.$invalid ||
                  $v.model.$invalid ||
                  $v.bundle.$invalid ||
                  editPushDevice.showLoading
              "
              :button-text="$t('PUSH_DEVICES_MGMT.EDIT.FORM.SUBMIT')"
              :loading="editPushDevice.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('PUSH_DEVICES_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
/* eslint no-console: 0 */
import { required } from 'vuelidate/lib/validators';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  props: {
    id: { type: Number, default: null },
    edToken: { type: String, default: '' },
    edName: { type: String, default: '' },
    edVersion: { type: String, default: '' },
    edModel: { type: String, default: '' },
    edBundle: { type: String, default: '' },
    onClose: { type: Function, default: () => {} },
  },
  data() {
    return {
      editPushDevice: {
        showAlert: false,
        showLoading: false,
        message: '',
      },
      token: this.edToken,
      name: this.edName,
      version: this.edVersion,
      model: this.edModel,
      bundle: this.edBundle,
      show: true,
    };
  },
  validations: {
    token: {
      required,
    },
    name: {
      required,
    },
    version: {
      required,
    },
    model: {
      required,
    },
    bundle: {
      required,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('PUSH_DEVICES_MGMT.EDIT.TITLE')} - ${this.edToken}`;
    },
  },
  methods: {
    setPageName({ name }) {
      this.$v.token.$touch();
      this.token = name;
    },
    showAlert() {
      bus.$emit('newToastMessage', this.editPushDevice.message);
    },
    resetForm() {
      this.token = '';
      this.name = '';
      this.version = '';
      this.model = '';
      this.bundle = '';
      this.$v.token.$reset();
      this.$v.name.$reset();
      this.$v.version.$reset();
      this.$v.model.$reset();
      this.$v.bundle.$reset();
    },
    editPushDevices() {
      // Show loading on button
      this.editPushDevice.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('updatePushDevices', {
          id: this.id,
          push_devices_token: this.token,
          push_devices_name: this.name,
          push_devices_version: this.version,
          push_devices_model: this.model,
          push_devices_bundle: this.bundle,
        })
        .then(() => {
          // Reset Form, Show success message
          this.editPushDevice.showLoading = false;
          this.editPushDevice.message = this.$t(
            'PUSH_DEVICES_MGMT.EDIT.API.SUCCESS_MESSAGE'
          );
          this.showAlert();
          this.resetForm();
          setTimeout(() => {
            this.onClose();
          }, 10);
        })
        .catch(() => {
          this.editPushDevice.showLoading = false;
          this.editPushDevice.message = this.$t(
            'PUSH_DEVICES_MGMT.EDIT.API.ERROR_MESSAGE'
          );
          this.showAlert();
        });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
}
</style>
